<!--
 * @Author: your name
 * @Date: 2021-06-26 20:27:07
 * @LastEditTime: 2021-07-02 09:39:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \nice-store-new\src\views\otherPage\innerIndex\index.vue
-->
/**
 * 内页入口
 *
 * @author hezhendong 20210316
 */
<template>
  <div>
    <el-row :gutter="24">
      <el-col :span="24">
        <div class="container container_inner">
          <router-view />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "innerIndex",
  data() {
    return {
      isPlatTplePc: true,
    };
  },
  created() {
    if (
      /Mobile|Android|webOS|iPhone|iPad|Phone/i.test(window.navigator.userAgent)
    ) {
      this.isPlatTplePc = false;
    } else {
      this.isPlatTplePc = true;
    }
  },
};
</script>
<style lang="scss" scoped>
.home {
  // width: 100%;
  // margin: 0 auto;
}
.container_inner {
  min-height: 72vh;
}
.first_footer {
  width: 100%;
}
@media (max-width: 768px) {
  .container {
    margin-top: 44px;
  }
}
</style>